import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import img40 from "../../../Assest/Images/img/WhatsApp Image 2024-03-18 at 12.58.06 PM.jpeg";
import img41 from "../../../Assest/Images/IMG-20240513-WA0115.jpg";
import img42 from "../../../Assest/Images/IMG-20240512-WA0259.jpg";
import img43 from "../../../Assest/Images/IMG-20240513-WA0001.jpg";
import img44 from "../../../Assest/Images/IMG-20240512-WA0336.jpg";
import img45 from "../../../Assest/Images/IMG-20240513-WA0005.jpg";
import img46 from "../../../Assest/Images/IMG_20240516_125403_716.jpg";
import img47 from "../../../Assest/Images/IMG-20240513-WA0004.jpg";
import img48 from "../../../Assest/Images/Screenshot_20240822_141302_Instagram.webp";
import img49 from "../../../Assest/Images/Screenshot_20240822_141307_Instagram.webp";
import img50 from "../../../Assest/Images/Screenshot_20240822_141311_Instagram.webp";
import img51 from "../../../Assest/Images/Screenshot_20240822_141321_Instagram.webp";
import img52 from "../../../Assest/Images/Screenshot_20240822_141325_Instagram.webp";
import img53 from "../../../Assest/Images/Screenshot_20240822_141351_Instagram.webp";
import img54 from "../../../Assest/Images/Screenshot_20240822_141413_Instagram.webp";
import img55 from "../../../Assest/Images/Screenshot_20240822_141420_Instagram.webp";
import img56 from "../../../Assest/Images/Screenshot_20240822_141428_Instagram.webp";
import img57 from "../../../Assest/Images/Screenshot_20240826_114354_Instagram.webp";
import img58 from "../../../Assest/Images/Screenshot_20240826_114336_Instagram.webp";
import img59 from "../../../Assest/Images/Screenshot_20240826_114230_Instagram.webp";
import img60 from "../../../Assest/Images/Screenshot_20240826_114242_Instagram.webp";
import img61 from "../../../Assest/Images/Screenshot_20240826_114217_Instagram.webp";
import img62 from "../../../Assest/Images/Screenshot_20240826_114224_Instagram.webp";
import img63 from "../../../Assest/Images/Screenshot_20240826_114403_Instagram.webp";
import img64 from "../../../Assest/Images/Screenshot_20240826_114247_Instagram.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

function OurGalary() {
  const responsive = {
    0: {
      items: 1,
    },

    600: {
      items: 2,
      itemsFit: "contain",
    },
    720: {
      items: 3,
      itemsFit: "contain",
    },
    1024: {
      items: 3,
      itemsFit: "contain",
    },
  };
  const data = [
    {
      id: 9,
      img: img48,
    },
    {
      id: 10,
      img: img49,
    },
    {
      id: 11,
      img: img50,
    },
    {
      id: 11,
      img: img51,
    },
    {
      id: 12,
      img: img52,
    },
    {
      id: 13,
      img: img53,
    },
    {
      id: 14,
      img: img54,
    },
    {
      id: 15,
      img: img55,
    },
    {
      id: 16,
      img: img56,
    },
    {
      id: 1,
      img: img40,
    },
    {
      id: 2,
      img: img41,
    },
    {
      id: 3,
      img: img42,
    },
    {
      id: 4,
      img: img43,
    },
    {
      id: 5,
      img: img44,
    },
    {
      id: 6,
      img: img45,
    },
    {
      id: 7,
      img: img46,
    },
    {
      id: 8,
      img: img47,
    },
    {
      id: 9,
      img: img48,
    },
    {
      id: 10,
      img: img49,
    },
    {
      id: 11,
      img: img50,
    },
    {
      id: 11,
      img: img51,
    },
    {
      id: 12,
      img: img52,
    },
    {
      id: 13,
      img: img53,
    },
    {
      id: 14,
      img: img54,
    },
    {
      id: 15,
      img: img55,
    },
    {
      id: 16,
      img: img56,
    },
    {
      id: 17,
      img: img57,
    },
    {
      id: 18,
      img: img58,
    },
    {
      id: 19,
      img: img59,
    },
    {
      id: 20,
      img: img60,
    },
    {
      id: 21,
      img: img61,
    },
    {
      id: 22,
      img: img62,
    },
    {
      id: 23,
      img: img63,
    },
    {
      id: 24,
      img: img64,
    },
  ];
  return (
    <AliceCarousel
      responsive={responsive}
      disableButtonsControls
      disableDotsControls
      mouseTracking
      autoPlay
      autoPlayInterval={5000}
      infinite
      style={{ Width: "100%", height: "100%" }}
    >
      {data?.map((item) => (
        <LazyLoadImage
          alt={item?.img}
          src={item?.img}
          className="ourGalaryImage "
          style={{ height: "25rem", objectFit: "cover", cursor: "pointer" }}
        />
      ))}
    </AliceCarousel>
  );
}

export default OurGalary;
